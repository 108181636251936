import { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useElementHelpers } from "./useElementHelpers";
import { useTimelineHelpers } from "./useTimelineHelpers";
import { useSeeking } from "./useSeeking";
import { useEvents } from "./useEvents";
import { useTimestamps } from "./useTimestamps";
import { useTimelineVisuals } from "./useTimelineVisuals";
import { msToPx, msToSeconds } from "./extensions";
import useVideoRanges from "./useVideoRanges";
import Player from "video.js/dist/types/player";

export type RefList = {
  video: MutableRefObject<HTMLVideoElement | null>;
  container: RefObject<HTMLDivElement>;
  timeline: RefObject<HTMLDivElement>;
  indicator: RefObject<HTMLDivElement>;
  backgroundSvg: RefObject<HTMLDivElement>;
  timelineStartDate: MutableRefObject<string | null>;
  originalTimelineStartDate: MutableRefObject<string | null>;
  videoInfo: MutableRefObject<{ realCurrentTime: number; duration: number; timeDeletedInMs: number; width: number }>;
  playerRef: MutableRefObject<Player | null>;
};
export type PRange = {
  id: string;
  start: number;
  end: number;
  duration: number;
};

export default function useTimelineUpdater() {
  const video = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const timeline = useRef<HTMLDivElement>(null);
  const indicator = useRef<HTMLDivElement>(null);
  const backgroundSvg = useRef<HTMLDivElement>(null);
  const videoInfo = useRef({ realCurrentTime: 0, duration: 0, timeDeletedInMs: 0, width: 0 });

  const timelineStartDate = useRef<string | null>(null);
  const originalTimelineStartDate = useRef<string | null>(null);

  const refs: RefList = useMemo(
    () => ({
      video,
      timeline,
      container,
      indicator,
      timelineStartDate,
      originalTimelineStartDate,
      backgroundSvg,
      videoInfo,
      playerRef
    }),
    []
  );

  const ignoreNextTimeUpdate = useRef(false);
  const TH = useTimelineHelpers({ refs });
  const elementHelpers = useElementHelpers({ refs, TH });
  const Events = useEvents({ elementHelpers, refs, TH });
  const Visuals = useTimelineVisuals({ refs, TH, sync: () => sync() });
  const { renderTimestamps, updateTimestamps } = useTimestamps({
    refs,
    elementHelpers,
    TH
  });
  const { hasVideoDataForCurrentTime, renderPlayableRanges } = useVideoRanges({
    elementHelpers,
    refs,
    TH
  });

  const updateMetaTime = useCallback(() => {
    const event = new CustomEvent("meta-time-update", {
      detail: { time: TH.getCurrentPlayerDateTime(), hasDataForTime: hasVideoDataForCurrentTime() }
    });
    document.dispatchEvent(event);
  }, [TH, hasVideoDataForCurrentTime]);

  const updateStartDate = useCallback((e: Event) => {
    const {
      detail: { startDate: startTime }
    } = e as TimelinePlaylistEvent;
    const startDate = new Date(startTime).toISOString();
    timelineStartDate.current = startDate;
    if (!originalTimelineStartDate.current) originalTimelineStartDate.current = startDate;
  }, []);

  const { startDrag, onScroll, setScrollableElements } = useSeeking({
    refs,
    Events,
    updateMetaTime,
    ignoreNextTimeUpdate
  });

  // const checkAndFixOldPlayback = useCallback(
  //   (e: Event) => {
  //     const { detail } = e as TimelinePlaylistEvent;
  //     const { startDate: newStartTime } = detail;
  //     const currentTime = TH.getCurrentPlayerDateTime();
  //     if (currentTime < newStartTime) {
  //       log.timeline("Fixing old playback");
  //       const seekTime = newStartTime - currentTime + 10000;
  //       addToCurrentTime(msToSeconds(seekTime));
  //     }
  //   },
  //   [TH, addToCurrentTime]
  // );

  const syncPlaylistUpdate = useCallback(
    (e: Event) => {
      updateStartDate(e);

      renderTimestamps();
      Events.deleteOldEvents();

      const negativeTimeUpdateInMs = TH.getNegativeTimeUpdate();
      syncUpdate.current.negativeTimeUpdateInMs = negativeTimeUpdateInMs;

      if (negativeTimeUpdateInMs < 0) {
        // data were deleted from timeline
        videoInfo.current.realCurrentTime += msToSeconds(negativeTimeUpdateInMs);
        videoInfo.current.timeDeletedInMs += Math.abs(negativeTimeUpdateInMs);
      }

      if (video.current && video.current.duration) {
        videoInfo.current.duration =
          video.current.duration === Infinity ? TH.getTotalDurationInSec() : video.current.duration;
      }

      videoInfo.current.width = videoInfo.current.duration - msToSeconds(videoInfo.current.timeDeletedInMs);
      if (timeline.current) timeline.current.style.width = `${videoInfo.current.width}px`;
    },
    [Events, TH, renderTimestamps, updateStartDate]
  );

  const syncUpdate = useRef<{ e: Event | null; negativeTimeUpdateInMs: number; run: boolean }>({
    e: null,
    negativeTimeUpdateInMs: 0,
    run: false
  });

  const syncVisuals = useCallback(() => {
    const { e, negativeTimeUpdateInMs, run } = syncUpdate.current;
    if (!run) return;
    syncUpdate.current.run = false;

    if (negativeTimeUpdateInMs >= 0) {
      // we have only new data
      if (e) renderPlayableRanges(e);
    } else {
      // data were deleted from timeline
      if (e) renderPlayableRanges(e, true);
      updateTimestamps(msToPx(negativeTimeUpdateInMs));
      Events.updateRenderedEvents(negativeTimeUpdateInMs);
    }
  }, [Events, renderPlayableRanges, updateTimestamps]);

  const sync = useCallback(() => {
    const { e } = syncUpdate.current;
    if (e) syncPlaylistUpdate(e);
    syncVisuals();
  }, [syncPlaylistUpdate, syncVisuals]);

  const onTimeUpdate = useCallback(() => {
    if (!video.current) return;

    if (video.current.currentTime) videoInfo.current.realCurrentTime = video.current.currentTime;

    Events.updateSkipButtons();
    if (!ignoreNextTimeUpdate.current) Visuals.moveTimeline();
    else ignoreNextTimeUpdate.current = false;
    updateMetaTime();
    Events.updateMetaEventsOnTimeUpdate();
    Visuals.rotatePlayerBasedOnImageRotation();
  }, [Events, Visuals, updateMetaTime]);

  const onPlaylistUpdate = useCallback((e: Event) => {
    syncUpdate.current.e = e;
    syncUpdate.current.run = true;

    dataSyncEmitter.emit("timeline-render");
  }, []);

  useEffect(() => {
    document.addEventListener("playlist-update", onPlaylistUpdate);
    return () => {
      document.removeEventListener("playlist-update", onPlaylistUpdate);
    };
  }, [onPlaylistUpdate]);

  return {
    onTimeUpdate,
    startDrag,
    onScroll,
    setScrollableElements,
    video,
    indicator,
    timeline,
    container,
    backgroundSvg,
    playerRef
  };
}
